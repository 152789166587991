import React from "react";
import {
  FaMobile,
  FaDesktop,
  FaNetworkWired,
  FaTools,
  FaRobot,
  FaWifi,
  FaShoppingBag,
  FaQuestionCircle,
  FaGraduationCap,
  FaUserFriends,
  FaLaptopCode,

} from "react-icons/fa";
import { Link } from "react-router-dom";

const Software = () => {
  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <h1 className="text-2xl text-customRed font-bold mb-8 text-center">
        Software Development Services
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <ServiceCard
          icon={<FaMobile />}
          title="Mobile Development"
          image="/images/mobileApp.jpeg"
          description="We specialize in creating mobile applications tailored to your business needs."
        />
        <ServiceCard
          icon={<FaDesktop />}
          title="Web Development"
          image="/images/web.jpeg"
          description="Build modern and responsive websites that enhance user experience and drive growth."
        />
        <ServiceCard
          icon={<FaNetworkWired />}
          title="System Integration"
          image="/images/integration.png"
          description="Integrate disparate systems seamlessly to streamline operations and improve efficiency."
        />
        <ServiceCard
          icon={<FaRobot />}
          title="Artificial Intelligence"
          image="/images/ai.jpg"
          description="Harness the power of AI to unlock insights, automate processes, and drive innovation."
        />
        <ServiceCard
          icon={<FaWifi />}
          title="Internet of Things"
          image="/images/iot.jpg"
          description="Create connected IoT solutions that enable smart interactions and improve decision-making."
        />
        <ServiceCard
          icon={<FaShoppingBag />}
          title="E-commerce Solutions"
          image="/images/shop.jpg"
          description="Develop robust e-commerce platforms that drive sales and enhance customer satisfaction."
        />

        <ServiceCard
          icon={<FaGraduationCap />}
          title="   Learning Management System"
          image="/images/lms.jpeg"
          description="Develop Platforms for organizing and delivering online courses."
        />
        <ServiceCard
          icon={<FaUserFriends />}
          title=" Customer Relationship Management"
          image="/images/crm3.jpeg"
          description="Develop Effective solutions to manage customer relationships."
        />
                <ServiceCard
          icon={<FaLaptopCode />}
          title="Custom Software Solutions"
          image="/images/custom-s.jpeg"
          description=" Custom software solutions tailored to meet specific business needs, enhancing efficiency and scalability."
        />
      </div>
    </div>
  );
};

const ServiceCard = ({ icon, title, image, description }) => {
  const handleEnquiryClick = () => {
    // Replace with your actual enquiry handling logic
    window.location.href = '/contact';
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="relative h-48">
        <img src={image} alt={title} className="object-cover w-full h-full" />
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white">
          <div className=" text-center px-4 ">
            <div className="text-3xl  mb-2">{icon}</div>
            <h2 className="text-xl font-semibold mb-2">{title}</h2>
            <p className="text-md ">{description}</p>
          </div>
        </div>
      </div>
      <div className="p-4 flex justify-center">
      <Link
        to="/contact"
        className="block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md flex items-center justify-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400"
      >
        <span className="flex justify-center items-center">
          <FaQuestionCircle className="mr-2" /> Enquire
        </span>
      </Link>
    </div>
    </div>
  );
};

export default Software;
