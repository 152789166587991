import React from 'react';

const Enrol = () => {
  return (
    <div className="flex justify-center bg-gray-50 min-h-screen">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfFJp7wlLVLjbyL2NYhn12eFa9CEduxKsXVN-sgboWNReDhyQ/viewform?embedded=true"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        className="w-full h-[100vh] max-w-4xl"
        title="Enrolment Form"
      >
        Loading…
      </iframe>
    </div>
  );
};

export default Enrol;
