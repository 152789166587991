export const licences = [
    {
      imgSrc: "/images/365.png",
      altText: "Office 365",
      title: "Office 365",
      description: "Office 365 is a cloud-based subscription service that brings together the best tools for the way people work today. By combining best-in-class apps with powerful cloud services, Office 365 lets anyone create and collaborate anywhere on any device.",
    },
    {
      imgSrc: "/images/windows11.webp",
      altText: "Windows 11",
      title: "Windows 11",
     
    },
    {
      imgSrc: "/images/sqlserver.webp",
      altText: "SQL Server",
      title: "SQL Server",
      description: "Microsoft SQL Server is a relational database management system developed by Microsoft. As a database server, it is a software product with the primary function of storing and retrieving data as requested by other software applications.",
    },
    {
      imgSrc: "/images/exchange.png",
      altText: "Exchange Server",
      title: "Exchange Server",
      description: "Microsoft Exchange Server is a mail server and calendaring server developed by Microsoft. It runs exclusively on Windows Server operating systems. The first version of Exchange Server to be published by Microsoft was Exchange Server 4.0.",
    },
    {
      imgSrc: "/images/project.webp",
      altText: "Project",
      title: "Project",
      description: "Microsoft Project is a project management software product, developed and sold by Microsoft. It is designed to assist a project manager in developing a schedule, assigning resources to tasks, tracking progress, managing the budget, and analyzing workloads.",
    }

    ,
    {
      imgSrc: "/images/exam.jpg",
      altText: "Microsoft Exam Voucher",
      title: "Microsoft Exam Voucher",
      description: "",
    }
  ]