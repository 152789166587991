import React from 'react';
import { motion, useInView } from 'framer-motion';
import { Link } from "react-router-dom";

const LeadGenerationSection = () => {
  const ref = React.useRef(null);
  const inView = useInView(ref, { once: true });

  return (
    <section className=" py-10" ref={ref}>
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="flex flex-col lg:flex-row items-center"
        >
          <div className="lg:w-1/2 mb-6 lg:mb-0">
            <motion.img
              initial={{ scale: 0.8, opacity: 0 }}
              animate={inView ? { scale: 1, opacity: 1 } : {}}
              transition={{ delay: 0.3, duration: 0.8 }}
              src="images/d.webp"
              alt="Business Transformation"
              className="rounded-md shadow-lg"
            />
          </div>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.6 }}
            className="lg:w-1/2 lg:pl-12"
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-4">Transform Your Business Today</h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ delay: 0.4, duration: 0.6 }}
              className="text-lg text-gray-600 leading-relaxed mb-6"
            >
              Unlock growth opportunities with our tailored solutions. Contact us now to discuss how we can elevate your business to the next level.
            </motion.p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-buttonBg1 hover:bg-buttonBg2 text-white px-4 py-2 rounded-full border-none cursor-pointer transition duration-300 transform hover:scale-105"
            >
              <Link to = 'contact'>   Contact Us</Link>
           
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default LeadGenerationSection;
