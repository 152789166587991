export const jobListings = [
    //{
    //   id: 1,
    //   title: 'Software Engineer',
    //   location: 'Remote',
    //   jobType: 'Full-time',
    //   description: 'Join our dynamic team and work on cutting-edge projects.',
    //   requirements: [
    //     'Experience with JavaScript, React.js, Node.js',
    //     'Knowledge of RESTful APIs',
    //     'Understanding of Agile methodologies'
    //   ],
    //   responsibilities: [
    //     'Develop and maintain web applications',
    //     'Collaborate with team members on projects',
    //     'Write clean and efficient code'
    //   ]
    // },
    // {
    //   id: 2,
    //   title: 'UX/UI Designer',
    //   location: 'New York',
    //   jobType: 'Part-time',
    //   description: 'Design user interfaces that provide exceptional user experiences.',
    //   requirements: [
    //     'Proficiency in Adobe Creative Suite',
    //     'Experience with user-centered design',
    //     'Strong portfolio showcasing design work'
    //   ],
    //   responsibilities: [
    //     'Design user interfaces and experiences',
    //     'Create wireframes and prototypes',
    //     'Collaborate with developers and stakeholders'
    //   ]
    // },
    // {
    //   id: 3,
    //   title: 'Data Scientist',
    //   location: 'San Francisco',
    //   jobType: 'Contract',
    //   description: 'Analyze data to provide actionable insights and predictions.',
    //   requirements: [
    //     'Strong analytical and problem-solving skills',
    //     'Proficiency in Python, R, or similar languages',
    //     'Experience with machine learning algorithms'
    //   ],
    //   responsibilities: [
    //     'Perform data analysis and visualization',
    //     'Build predictive models',
    //     'Work on data-driven projects'
    //   ]
    // }
  ];