import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaBriefcase,
  FaUsers,
  FaBuilding,
  FaChalkboardTeacher,
  FaPen,
  FaStoreAlt,
  FaHandshake,
  FaBars,
  FaTimes,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setSelectedService } from "../slices/trainingSlice";

const NavBar = () => {
  const [selectedTab, setSelectedTab] = useState("/");
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [isAboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleDropdown = (dropdownSetter, otherDropdownSetter) => {
    dropdownSetter(prev => !prev);
    otherDropdownSetter(false);
  };

  const handleLinkClick = (path) => {
    setSelectedTab(path);
    resetDropdowns();
  };

  const resetDropdowns = () => {
    setServicesDropdownOpen(false);
    setAboutDropdownOpen(false);
    setIsMenuOpen(false);
    dispatch(setSelectedService(""));
  };

  const menuItems = [
    { path: "/", label: "Home", clickHandler: handleLinkClick },
    {
      label: "Services",
      dropdown: true,
      isOpen: isServicesDropdownOpen,
      toggleDropdown: () => toggleDropdown(setServicesDropdownOpen, setAboutDropdownOpen),
      dropdownItems: [
        { path: "/services/training", label: "Training", icon: FaChalkboardTeacher },
        { path: "/services/consulting", label: "Consulting", icon: FaHandshake },
        { path: "/services/reseller", label: "Reseller", icon: FaStoreAlt },
      ],
    },
    {
      label: "About",
      dropdown: true,
      isOpen: isAboutDropdownOpen,
      toggleDropdown: () => toggleDropdown(setAboutDropdownOpen, setServicesDropdownOpen),
      dropdownItems: [
        { path: "/about/company", label: "Company", icon: FaBuilding },
        { path: "/about/team", label: "Team", icon: FaUsers },
        { path: "/careers", label: "Careers", icon: FaBriefcase },
      ],
    },
    { path: "/courses", label: "Courses", clickHandler: handleLinkClick },
    { path: "/kidscoding", label: "For Kids", clickHandler: handleLinkClick },
    { path: "/blog", label: "Blog", clickHandler: handleLinkClick },
    { path: "/gallery", label: "Gallery", clickHandler: handleLinkClick },
    { path: "/contact", label: "Contact Us", clickHandler: handleLinkClick },
  ];

  return (
    <div className="bg-white">
      <div className="hidden bg-customRed md:h-[5%] md:flex items-center justify-center text-gray-100 fixed top-0 w-full z-50">
        Join millions of trained professionals at a discounted price
        <Link
          to="/enrol"
          className="flex items-center md:px-2 md:ml-4 bg-yellow-500 text-white rounded-full hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400"
        >
          <FaPen className="text-black" />
          <span className="ml-2">Enrol Now</span>
        </Link>
      </div>
      <nav className="container mx-auto lg:flex items-center justify-between mt-[2.5%]">
        <Link to="/" onClick={() => handleLinkClick("/")}>
          <img
            src="/images/logo.png"
            alt="Company Logo"
            className="ml-2 md:m-0 h-24 md:h-22 flex items-center"
          />
        </Link>

        <div className="absolute top-[5%] right-5 md:hidden border border-slate-900 rounded flex items-center p-2 mr-2">
          <button
            className={`mobile-menu-button z-50`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <FaTimes className="text-2xl text-white" />
            ) : (
              <FaBars className="text-2xl" />
            )}
          </button>
        </div>

        <ul
          className={`text-customBlue md:flex font-sans font-semibold md:text-xl ${
            isMenuOpen
              ? "block text-white absolute top-0 md:top-[25%] w-full h-auto py-4 bg-black right-0 p-5 transition-height duration-300 z-30"
              : "hidden"
          }`}
        >
          {menuItems.map((item, index) =>
            item.dropdown ? (
              <li key={index} className="relative my-5 p-3 border-b border-gray-900 md:border-none">
                <button
                  className={`hover:text-customRed flex items-center ${
                    selectedTab === item.path && "text-customRed"
                  }`}
                  onClick={item.toggleDropdown}
                >
                  {item.label}
                  {item.isOpen ? <FaChevronUp className="ml-2 text-sm font-extralight" /> : <FaChevronDown className="ml-2 text-sm font-extralight" />}
                </button>
                {item.isOpen && (
                  <ul
                    className={`text-black absolute left-0 md:t-2 font-extralight flex justify-center w-full md:block bg-white shadow-md z-30 border md:w-fit`}
                  >
                    {item.dropdownItems.map((dropdownItem, idx) => (
                      <li key={idx} className="md:border-b border-gray-500">
                        <Link
                          to={dropdownItem.path}
                          className="block px-4 py-2 hover:bg-gray-100"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={() => handleLinkClick(dropdownItem.path)}
                        >
                          <dropdownItem.icon className="inline-block mr-2" /> {dropdownItem.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ) : (
              <li key={index} className="my-5 p-3 border-b border-gray-900 md:border-none">
                <Link
                  to={item.path}
                  className={`hover:text-customRed ${
                    selectedTab === item.path && "text-customRed"
                  }`}
                  onClick={() => item.clickHandler(item.path)}
                >
                  {item.label}
                </Link>
              </li>
            )
          )}
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
