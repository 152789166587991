//Services.jsx
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { dellProducts } from "../data/dellProducts";
import { licences } from "../data/microsoftLicenses";
import {
  FaCode,
  FaTools,
  FaShieldAlt,
  FaServer,
  FaCloud,
  FaLaptopCode,
  FaRobot,
  FaChartLine,
  FaBalanceScale,
} from "react-icons/fa";
import { QuoteModal } from "../components/QuoteModal";
import { useDispatch } from "react-redux";
import { setSelectedService } from "../slices/trainingSlice";
import { useSelector } from "react-redux";

const LicenseCard = ({ imgSrc, altText, title, description, onClick }) => (
  <div className="bg-white overflow-hidden shadow-lg rounded-lg">
    <div className="flex justify-center items-center">
      <img src={imgSrc} alt={altText} className="w-[50%] object-cover" />
      <div className="px-6 py-4 flex-grow">
        <h4 className="text-xl font-bold text-customRed">{title}</h4>
        <p className="text-gray-700 mt-2">{description}</p>
      </div>
    </div>
    <div className="px-6 py-4 flex items-center justify-center">
      <button
        onClick={onClick}
        className="bg-buttonBg1 hover:bg-buttonBg2 text-white px-4 py-2 rounded-full border-none cursor-pointer transition duration-300 transform hover:scale-105"
      >
        Get Quote
      </button>
    </div>
  </div>
);

const ServiceSection = ({ id, title, children }) => (
  <div
    id={id}
    className="overflow-hidden shadow rounded-lg px-4 py-6 sm:p-6 mb-8"
  >
    <div className="flex items-center justify-center mb-4">
      <h3 className="text-3xl font-semibold text-customRed">{title}</h3>
    </div>
    <div className="text-gray-700">{children}</div>
  </div>
);

const Services = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  //const [isMsModalOpen, setIsMsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hoverStates, setHoverStates] = useState({});

  const dispatch = useDispatch();

  const handleTrainingClick = (service) => {
    dispatch(setSelectedService(service));
  };

  const handleGetQuoteClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleMouseEnter = (index) => {
    setHoverStates((prevStates) => ({ ...prevStates, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setHoverStates((prevStates) => ({ ...prevStates, [index]: false }));
  };

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const parts = path.split("/");
    const serviceId = parts.length > 2 ? parts[2] : "";

    if (serviceId) {
      const element = document.getElementById(serviceId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location.pathname]);

  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <ServiceSection id="training" title="Training Services">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:p-6">
            {/* Microsoft Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition transform hover:shadow-xl hover:scale-105">
              <img
                className="w-full md:h-64 object-cover object-center"
                src="/images/micro.jpeg"
                alt="Microsoft Training"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  Microsoft
                </h3>
                <p className="text-gray-700 mb-4">
                  Master Office 365 to Azure with our tailored Microsoft
                  training programs.
                </p>
                <Link
                  to="/courses/microsoft"
                  onClick={() => handleTrainingClick("Microsoft")}
                  className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium px-4 py-2 rounded-full shadow-md transition duration-300 transform hover:scale-105"
                >
                  View Courses
                </Link>
              </div>
            </div>

            {/* CompTIA Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition transform hover:shadow-xl hover:scale-105">
              <img
                className="w-full md:h-64 object-cover object-center"
                src="/images/comp.jpeg"
                alt="CompTIA Training"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  CompTIA
                </h3>
                <p className="text-gray-700 mb-4">
                  Prepare for globally recognized A+, Network+, and Security+
                  certifications with our expert-led courses.
                </p>
                <Link
                  to="/courses/comptia"
                  onClick={() => handleTrainingClick("CompTIA")}
                  className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium px-4 py-2 rounded-full shadow-md transition duration-300 transform hover:scale-105"
                >
                  View Courses
                </Link>
              </div>
            </div>

            {/* Cisco Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition transform hover:shadow-xl hover:scale-105">
              <img
                className="w-full md:h-64 object-cover object-center"
                src="/images/csc.jpeg"
                alt="Cisco Training"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-800 mb-2">Cisco</h3>
                <p className="text-gray-700 mb-4">
                  Specialize in Cisco networking solutions through our focused
                  training modules.
                </p>
                <Link
                  to="/courses"
                 onClick={() => handleTrainingClick("Cisco")}
                  className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium px-4 py-2 rounded-full shadow-md transition duration-300 transform hover:scale-105"
                >
                  View Courses
                </Link>
              </div>
            </div>

            {/* Kids Code Academy Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition transform hover:shadow-xl hover:scale-105">
              <img
                className="w-full md:h-64 object-cover object-center"
                src="/images/kid.jpeg"
                alt="Kids Code Academy"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  Kids Code Academy
                </h3>
                <p className="text-gray-700 mb-4">
                  We teach kids how to code from an early age.
                </p>
                <Link
                  to="/kidscoding"
                  className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium px-4 py-2 rounded-full shadow-md transition duration-300 transform hover:scale-105"
                >
                  Learn More
                </Link>
              </div>
            </div>

            {/* Soft Skills Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition transform hover:shadow-xl hover:scale-105">
              <img
                className="w-full md:h-64 object-cover object-center"
                src="/images/sskill.jpeg"
                alt="Soft Skills Training"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  Soft Skills
                </h3>
                <p className="text-gray-700 mb-4">
                  Develop essential soft skills like communication, leadership,
                  and teamwork.
                </p>
                <Link
                  to="/courses/soft-skills"
                  onClick={() => handleTrainingClick("Soft Skills")}
                  className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium px-4 py-2 rounded-full shadow-md transition duration-300 transform hover:scale-105"
                >
                  Explore Courses
                </Link>
              </div>
            </div>

            {/* Learnerships Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden transition transform hover:shadow-xl hover:scale-105">
              <img
                className="w-full md:h-64 object-cover object-center"
                src="/images/grad.jpeg"
                alt="Learnerships"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  Learnerships
                </h3>
                <p className="text-gray-700 mb-4">
                  Join SETA accredited and structured programs blend practical
                  and theoretical learning.
                </p>
                <Link
                  to="/careers"
                  className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium px-4 py-2 rounded-full shadow-md transition duration-300 transform hover:scale-105"
                >
                  Apply
                </Link>
              </div>
            </div>

            {/* View All Courses CTA Button */}
            <div className="text-center col-span-full mt-6">
              <Link
                to="/courses"
                className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium px-6 py-3 rounded-full shadow-md transition duration-300 transform hover:scale-105"
              >
                View All Courses
              </Link>
            </div>
          </div>
        </ServiceSection>

        <ServiceSection id="consulting" title="Consulting Services">
          {/* <p className="text-lg leading-7 text-gray-700">
          We offer expert consulting services to drive your business forward. Our seasoned consultants work closely with you to understand your challenges and develop tailored solutions. Our services include
          </p> */}

          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
            {/* Service Item 1 */}
            <li className="service-item hover:bg-customRed">
              <div className="p-5 flex flex-col items-center justify-center bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300 ease-in-out h-full">
                <FaCode className=" text-4xl mb-2" />
                <strong className="block text-xl text-customBlue mb-2 hover:text-customBlue cursor-pointer">
                  Software Development
                </strong>
                <p className="text-gray-700 text-center">
                  End-to-end services from concept to deployment.
                </p>
                <p className="text-sm text-gray-600 mt-2 hover:underline">
                  <Link to="consulting/software">
                    learn more <span className="text-customBlue">→</span>
                  </Link>
                </p>
              </div>
            </li>

            {/* Service Item 2 */}
            <li className="service-item hover:bg-customRed">
              <div className="p-5 flex flex-col items-center justify-center bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300 ease-in-out h-full">
                <FaTools className=" text-4xl mb-2" />
                <strong className="block text-xl text-customBlue mb-2 hover:text-customBlue cursor-pointer">
                  Support & Maintenance
                </strong>
                <p className="text-gray-700 text-center">
                  Ensuring high uptime for seamless and efficient IT system
                  operations.
                </p>
                <p className="text-sm text-gray-600 mt-2 hover:underline">
                  <Link to="consulting/support">
                    learn more <span className="text-customBlue">→</span>
                  </Link>
                </p>
              </div>
            </li>
            {/* Service Item 4 */}
            <li className="service-item hover:bg-customRed">
              <div className="p-5 flex flex-col items-center justify-center bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300 ease-in-out h-full">
                <FaShieldAlt className=" text-4xl mb-2" />
                <strong className="block text-xl text-customBlue mb-2 hover:text-customBlue cursor-pointer">
                  Cybersecurity
                </strong>
                <p className="text-gray-700 text-center">
                  Implementing robust measures to protect your business.
                </p>
                <p className="text-sm text-gray-600 mt-2 hover:underline">
                  <Link to="consulting/cybersecurity">
                    learn more <span className="text-customBlue">→</span>
                  </Link>
                </p>
              </div>
            </li>

            {/* Service Item 5 */}
            <li className="service-item hover:bg-customRed">
              <div className="p-5 flex flex-col items-center justify-center bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300 ease-in-out h-full">
                <FaCloud className=" text-4xl mb-2" />
                <strong className="block text-xl text-customBlue mb-2 hover:text-customBlue cursor-pointer">
                  Cloud Solutions
                </strong>
                <p className="text-gray-700 text-center">
                  Utilizing cloud platforms for scalability, efficiency, and
                  cost-effectiveness.
                </p>
                <p className="text-sm text-gray-600 mt-2 hover:underline">
                  <Link to="consulting/cloud">
                    learn more <span className="text-customBlue">→</span>
                  </Link>
                </p>
              </div>
            </li>

            {/* Service Item 6 */}
            <li className="service-item hover:bg-customRed">
              <div className="p-5 flex flex-col items-center justify-center bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300 ease-in-out h-full">
                <FaLaptopCode className=" text-4xl mb-2" />
                <strong className="block text-xl text-customBlue mb-2 hover:text-customBlue cursor-pointer">
                  Digital Transformation
                </strong>
                <p className="text-gray-700 text-center">
                  Enhancing operations, customer experiences, and fostering
                  innovation.
                </p>
                <p className="text-sm text-gray-600 mt-2 hover:underline">
                  <Link to="consulting/transformation">
                    learn more <span className="text-customBlue">→</span>
                  </Link>
                </p>
              </div>
            </li>

            {/* Service Item 12 */}
            <li className="service-item hover:bg-customRed">
              <div className="p-5 flex flex-col items-center justify-center bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300 ease-in-out h-full">
                <FaServer className=" text-4xl mb-2" />
                <strong className="block text-xl  mb-2 hover:text-customBlue cursor-pointer text-customBlue">
                  Hosting
                </strong>
                <p className="text-gray-700 text-center">
                  Reliable hosting solutions for secure and accessible websites.
                </p>
                <p className="text-sm text-gray-600 mt-2 hover:underline">
                  <Link to="consulting/hosting">
                    learn more <span className="text-customBlue">→</span>
                  </Link>
                </p>
              </div>
            </li>

            <li className="service-item hover:bg-customRed">
              <div className="p-5 flex flex-col items-center justify-center bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300 ease-in-out h-full">
                <FaRobot className=" text-4xl mb-2" />
                <strong className="block text-xl  mb-2 hover:text-customBlue cursor-pointer text-customBlue">
                  AI and Machine Learning
                </strong>
                <p className="text-gray-700 text-center">
                  Using AI and machine learning for innovation and competitive
                  advantage
                </p>
                <p className="text-sm text-gray-600 mt-2 hover:underline">
                  <Link to="consulting/ai-ml">
                    learn more <span className="text-customBlue">→</span>
                  </Link>
                </p>
              </div>
            </li>

            <li className="service-item hover:bg-customRed">
              <div className="p-5 flex flex-col items-center justify-center bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300 ease-in-out h-full">
                <FaBalanceScale className=" text-4xl mb-2" />
                <strong className="block text-xl  mb-2 hover:text-customBlue cursor-pointer text-customBlue">
                  Compliance and Regulations
                </strong>
                <p className="text-gray-700 text-center">
                  Ensuring compliance and navigating regulatory landscapes for
                  businesses.
                </p>
                <p className="text-sm text-gray-600 mt-2 hover:underline">
                  <Link to="consulting/compliance">
                    learn more <span className="text-customBlue">→</span>
                  </Link>
                </p>
              </div>
            </li>
            <li className="service-item hover:bg-customRed">
              <div className="p-5 flex flex-col items-center justify-center bg-white shadow-md rounded-lg hover:shadow-lg transition duration-300 ease-in-out h-full">
                <FaChartLine className=" text-4xl mb-2" />
                <strong className="block text-xl  mb-2 hover:text-customBlue cursor-pointer text-customBlue">
                  Data Analytics
                </strong>
                <p className="text-gray-700 text-center">
                  Utilizing data-driven insights to optimize business strategies
                  and decision-making
                </p>
                <p className="text-sm text-gray-600 mt-2 hover:underline">
                  <Link to="consulting/data">
                    learn more <span className="text-customBlue">→</span>
                  </Link>
                </p>
              </div>
            </li>
          </ul>

          <div className="mt-8 flex justify-center">
            <a
              className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-full shadow-md transition duration-300 transform hover:scale-105"
              href="/contact"
            >
              Partner with us
            </a>
          </div>
        </ServiceSection>

        <ServiceSection id="reseller" title="Reseller Services">
          {/* <p>
          We are a Microsoft and Dell Authorized Reseller, for competitive pricing and expert IT support. We help reduce costs, minimize downtime, and enhance business growth.
          </p> */}
          <h4 className="text-lg font-semibold mt-6 flex justify-center items-center">
            Featured Dell Products
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
            {dellProducts &&
              dellProducts.map((product, index) => (
                <div
                  key={index}
                  className="bg-white overflow-hidden shadow-lg rounded-lg"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                >
                  <div className="flex justify-center items-center">
                    <img
                      src={
                        hoverStates[index] ? product.imgSrc2 : product.imgSrc
                      }
                      alt={product.altText}
                      className="w-[50%] object-cover"
                    />
                    <div className="px-6 py-4 flex-grow">
                      <h4 className="text-xl font-bold text-customRed">
                        {product.title}
                      </h4>
                      <p className="text-gray-700 mt-2">
                        {product.description}
                      </p>
                    </div>
                  </div>
                  <div className="px-6 py-4 flex items-center justify-center">
                    <button
                      onClick={() => handleGetQuoteClick(product)}
                      className="bg-buttonBg1 hover:bg-buttonBg2 text-white px-4 py-2 rounded-full transition duration-300 transform hover:scale-105"
                    >
                      Get Quote
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <h4 className="text-lg font-semibold text-gray-900 mt-6 flex   justify-center items-center p-10">
            Microsoft Licenses and Exam Vouchers
          </h4>
          <div className="text-sm grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
            {licences &&
              licences.map((product, index) => (
                <LicenseCard
                  key={index}
                  imgSrc={product.imgSrc}
                  altText={product.altText}
                  title={product.title}
                  //  description={product.description}
                  onClick={() => handleGetQuoteClick(product)} // Pass handleMsQuoteClick as onClick handler
                />
              ))}
          </div>
        </ServiceSection>
      </div>
      {isModalOpen && (
        <QuoteModal
          className="z-50"
          product={selectedProduct}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Services;
