import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { FaGlobe, FaBullseye, FaUsers, FaTrophy, FaStar, FaMedal } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

// HeroSection Component
const HeroSection = () => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  return (
    <section className="relative bg-gray-800 text-white py-20">

      <Helmet>
        <title>About Us - Ikusasa Technology  Solutions</title>
        <meta name="description" content="Learn more about us and what we do" />
      </Helmet>
      <div className="absolute inset-0">
        <img src="/images/hs-about.jpeg" alt="Hero Background" className="object-cover w-full h-full" />
        <div className="absolute inset-0 bg-black opacity-50" aria-hidden="true"></div>
      </div>
      <div className="relative container mx-auto px-6 text-center">
        <motion.h1
          className="text-5xl font-bold mb-4"
          initial={{ opacity: 0, y: -50 }}
          animate={controls}
          transition={{ duration: 1 }}
        >
          Welcome to Ikusasa Technology Solution
        </motion.h1>
        <motion.p
          className="text-lg mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={controls}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Leading the way in ICT Training and Consulting across the African continent.
        </motion.p>
        <motion.a
          href="#company-overview"
          className="bg-customRed hover:bg-red-600 text-white px-4 py-2 rounded-full border-none cursor-pointer transition duration-300 transform hover:scale-105"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={controls}
          transition={{ duration: 0.5, delay: 1 }}
        >
          Learn More
        </motion.a>
      </div>
    </section>
  );
};

// CompanyOverview Component
const CompanyOverview = ({ title, description, sections }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  return (
    <section id="company-overview" className="bg-white py-20">
      <div className="container mx-auto px-6 text-center">
        <motion.h2
          className="text-4xl font-extrabold mb-8 text-customBlue"
          initial={{ opacity: 0, y: -50 }}
          animate={controls}
          transition={{ duration: 1 }}
        >
          {title}
        </motion.h2>
        <motion.div
          className="flex flex-col lg:flex-row lg:space-x-8"
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <div className="flex-1 mb-8 lg:mb-0">
            {description.map((para, index) => (
              <motion.p
                key={index}
                className="text-lg text-gray-800 leading-relaxed mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={controls}
                transition={{ duration: 0.5, delay: index * 0.3 }}
              >
                {para}
              </motion.p>
            ))}
          </div>
          <div className="flex-1 space-y-6">
            {sections.map(({ id, title, icon: Icon, description }) => (
              <motion.div
                key={id}
                className="flex items-start space-x-4 p-6 bg-blue-100 rounded-lg shadow-lg"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={controls}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <Icon className="w-12 h-12 text-customRed" aria-hidden="true" />
                <div>
                  <h3 className="text-2xl font-semibold mb-2 text-customBlue">{title}</h3>
                  <p className="text-lg text-gray-700">{description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

// AchievementsSection Component
// const AchievementsSection = ({ achievements }) => {
//   const controls = useAnimation();

//   useEffect(() => {
//     controls.start({ opacity: 1, y: 0 });
//   }, [controls]);

//   return (
//     <section id="achievements" className="bg-gray-100 py-20">
//       <div className="container mx-auto px-6 text-center">
//         <motion.h2
//           className="text-4xl font-extrabold mb-12 text-customBlue"
//           initial={{ opacity: 0, y: -50 }}
//           animate={controls}
//           transition={{ duration: 1 }}
//         >
//           Our Achievements
//         </motion.h2>
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//           {achievements.map(({ id, title, icon: Icon, description }) => (
//             <motion.div
//               key={id}
//               className="flex items-start space-x-4 p-6 bg-white rounded-lg shadow-lg hover:scale-105 transition-transform duration-500"
//               initial={{ opacity: 0, y: 50 }}
//               animate={controls}
//               transition={{ duration: 0.6, delay: 0.3 }}
//               whileHover={{ scale: 1.05 }}
//             >
//               <Icon className="w-12 h-12 text-customRed" aria-hidden="true" />
//               <div>
//                 <h3 className="text-2xl font-semibold mb-2 text-customBlue">{title}</h3>
//                 <p className="text-lg text-gray-700">{description}</p>
//               </div>
//             </motion.div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// TeamSection Component
const TeamSection = ({ members }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  return (
    <section id="team" className="bg-white py-20">
      <div className="container mx-auto px-6 text-center">
        <motion.h2
          className="text-4xl font-extrabold mb-12 text-customBlue"
          initial={{ opacity: 0, y: -50 }}
          animate={controls}
          transition={{ duration: 1 }}
        >
          Our Team
        </motion.h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {members.map(({ id, name, role }) => (
            <motion.div
              key={id}
              className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg hover:scale-105 transition-transform duration-500"
              initial={{ opacity: 0, y: 50 }}
              animate={controls}
              transition={{ duration: 0.6, delay: id * 0.1 }}
              whileHover={{ scale: 1.05 }}
            >
              {/* If you have images, add an img element here */}
              {/* <img src="path_to_image" alt={`${name}`} className="w-24 h-24 rounded-full mb-4" /> */}
              <h3 className="text-xl font-semibold mb-2 text-customRed">{name}</h3>
              <p className="text-lg text-gray-700">{role}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

// About Component
const About = () => {
  const location = useLocation();
  const companyControls = useAnimation();
  const achievementsControls = useAnimation();
  const teamControls = useAnimation();

  useEffect(() => {
    const path = location.pathname;
    const parts = path.split('/');
    const sectionId = parts.length > 2 ? parts[2] : '';

    if (sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.warn(`Element with id "${sectionId}" not found.`);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    companyControls.start({ opacity: 1, y: 0 });
    achievementsControls.start({ opacity: 1, y: 0 });
    teamControls.start({ opacity: 1, y: 0 });
  }, [companyControls, achievementsControls, teamControls]);

  const companyInfo = {
    title: "About Us",
    description: [
      "Ikusasa Technology Solution is a company specializing in Information, Communication, and Technology (ICT) training and consulting services. Our success is driven by the diverse and specialized services we offer to our clients.",
      "We operate with best practices in General Management, Finance, Human Resources, and Customer Care, providing a wide range of services including ICT hardware and software solutions, applications development, networking, data communication, soft skills training, and ICT certifications.",
      "In collaboration with internationally recognized brands such as Alcatel, CompTIA, IBM, Microsoft, Digital Marketing Institute, and MICT Seta, we deliver high-quality, tailored solutions to meet our clients' needs."
    ],
    sections: [
      { id: "vision", title: "Vision", icon: FaGlobe, description: "To be a leading provider for ICT Training and Consulting services across the African continent." },
      { id: "mission", title: "Mission", icon: FaBullseye, description: "To grow every customer’s business through our professional team members, enhancing mutual benefits." },
      { id: "empowerment", title: "Empowerment", icon: FaUsers, description: "We strive to advance individuals and foster sustainable community development." }
    ]
  };

  const teamMembers = [
    { id: 1, name: 'Thulisile Dlamini', role: 'Chief Executive Officer (CEO)' },
    { id: 2, name: 'Keneilwe Makhubela', role: 'Operational Manager' },
    { id: 3, name: 'Yvone Pitswani', role: 'Human Resource Manager' },
    { id: 4, name: 'Tiyani Baloyi', role: 'Full Stack Developer' },
    { id: 5, name: 'Nokuthula Gumede', role: 'Administrator' },
    { id: 6, name: 'Angel Malatji', role: 'Front End Developer' },
    { id: 7, name: 'Tshepang Khumalo', role: 'UI/UX Designer/Developer' },
    { id: 8, name: 'Njabulo Lamula', role: 'Technical Support Specialist' },

  ];

  // const achievements = [
  //   { id: 1, title: '2022 GovTech Digital Service Awards', icon: FaTrophy, description: 'Awarded for outstanding contributions in Digital ICT training' },
  //   { id: 2, title: 'Top Innovator', icon: FaStar, description: 'Recognized for innovative solutions in ICT applications and services.' },
  //   { id: 3, title: 'Community Impact Award', icon: FaMedal, description: 'Honored for significant impact on community development and empowerment.' }
  // ];

  return (
    <div className="bg-gray-50 min-h-screen">
      <HeroSection />
      <CompanyOverview 
        title={companyInfo.title} 
        description={companyInfo.description} 
        sections={companyInfo.sections} 
        controls={companyControls}
      />
      {/* <AchievementsSection achievements={achievements} controls={achievementsControls} /> */}
      <TeamSection members={teamMembers} controls={teamControls} />
    </div>
  );
}

export default About;
