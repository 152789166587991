import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaRegCommentDots,
  FaPaperPlane,
} from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    contactNumber: "",
    subject: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState("");

  const recaptchaRef = useRef(null);

  const validateField = (name, value) => {
    switch (name) {
      case "name":
        return value.trim() ? "" : "Name is required";
      case "email":
        return /\S+@\S+\.\S+/.test(value) ? "" : "Email is invalid";
      case "contactNumber":
        return /^\d{10}$/.test(value) ? "" : "Contact number is invalid";
      case "message":
        return value.trim() ? "" : "Message is required";
      case "subject":
        return value.trim() ? "" : "Subject is required";
      default:
        return "";
    }
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) errors[key] = error;
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting || !validateForm() || !recaptchaValue) {
      if (!recaptchaValue) {
        setRecaptchaError("Please complete the reCAPTCHA before submitting the form.");
      }
      return;
    }

    try {
      setSubmitting(true);
      const response = await axios.post(
        "https://ikusasasolutions.co.za/contact.php",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          title: "Thank You!",
          text: "Your query has been submitted successfully. Our team will contact you shortly.",
          icon: "success",
          confirmButtonText: "Ok",
        });
        setSubmitStatus("success");
        recaptchaRef.current.reset(); // Reset reCAPTCHA
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was an error submitting your query. Please try again or contact info@ikusasatech.com for assistance.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setSubmitStatus("error");
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
      setRecaptchaValue(null); // Clear reCAPTCHA value
      setRecaptchaError(""); // Clear reCAPTCHA error
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaError(""); // Clear reCAPTCHA error when reCAPTCHA is completed
  };

  useEffect(() => {
    if (submitStatus === "success") {
      setFormData({
        name: "",
        email: "",
        message: "",
        contactNumber: "",
        subject: "",
      });
      setTimeout(() => setSubmitStatus(null), 3000);
    }
  }, [submitStatus]);

  return (
    <div
      className="min-h-full py-[10%] md:py-0 md:min-h-full bg-gray-600 flex flex-col items-center justify-center"
      style={{
        backgroundImage: `url('/images/contact.jpeg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container mx-auto flex flex-wrap justify-center md:mt-5">
        <form
          className="mx-2 md:max-w-md w-full bg-white rounded-lg px-8 pt-6 pb-8 md:mb-4 flex flex-col"
          onSubmit={handleSubmit}
        >
          <h1 className="text-2xl mb-5 flex justify-center items-center font-semibold text-customRed">
            Contact Us
          </h1>

          <FormField
            id="name"
            name="name"
            type="text"
            placeholder="Name"
            icon={<FaUser className="text-customBlue mr-4" />}
            value={formData.name}
            onChange={handleChange}
            error={formErrors.name}
          />
          <FormField
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            icon={<FaEnvelope className="text-customBlue mr-4" />}
            value={formData.email}
            onChange={handleChange}
            error={formErrors.email}
          />
          <FormField
            id="contactNumber"
            name="contactNumber"
            type="text"
            placeholder="Contact Number"
            icon={<FaPhone className="text-customBlue mr-4" />}
            value={formData.contactNumber}
            onChange={handleChange}
            error={formErrors.contactNumber}
          />
          <FormField
            id="subject"
            name="subject"
            type="text"
            placeholder="Subject"
            icon={<FaRegCommentDots className="text-customBlue mr-4" />}
            value={formData.subject}
            onChange={handleChange}
            error={formErrors.subject}
          />
          <div className="mb-6 flex items-center">
            <FaRegCommentDots className="text-customBlue mr-4" />
            <textarea
              id="message"
              name="message"
              rows="4"
              required
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formErrors.message ? "border-red-500" : ""
              }`}
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {formErrors.message && (
              <p className="text-red-500 text-xs italic">
                {formErrors.message}
              </p>
            )}
          </div>
          <div className="mb-6 flex justify-center">
            <ReCAPTCHA
              sitekey= {process.env.REACT_APP_API_KEY}
              onChange={handleRecaptchaChange}
              ref={recaptchaRef} // Set reference to reCAPTCHA
            />
            {recaptchaError && (
              <p className="text-red-500 text-xs italic">{recaptchaError}</p>
            )}
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="bg-customBlue text-white font-bold py-2 px-4 rounded flex items-center"
              disabled={submitting}
            >
              {submitting ? (
                <>
                  <ClipLoader color="#fff" size={24} />
                  <span className="ml-2">Submit</span>
                </>
              ) : (
                <>
                  <FaPaperPlane className="mr-2" />
                  Submit
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Extracted FormField component for reusability
const FormField = ({ id, name, type, placeholder, icon, value, onChange, error }) => (
  <div className="mb-4 flex items-center">
    {icon}
    <input
      type={type}
      id={id}
      name={name}
      required
      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
        error ? "border-red-500" : ""
      }`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    {error && (
      <p className="text-red-500 text-xs italic">{error}</p>
    )}
  </div>
);

export default ContactForm;
