import React from 'react'
import HeroSection from '../components/HeroSection'
import Features from '../components/Features'
import CallToAction from '../components/CallToAction'
import Benefits from '../components/Benefits'
import Testimonials from '../components/Testimonials'
import Partners from '../components/Partners'
import { Helmet } from 'react-helmet'

const Home = () => {
  return (
    <div  className='bg-white'>
        <Helmet>
        <title>Home Page - Ikusasa Technology  Solutions</title>
        <meta name="description" content="Welcome to our Website. Explore our products, services, and latest updates. Discover more about what we offer and how we can help you" />
        <meta property="og:description" content="Explore our site for the latest products, services, and updates. Learn more about how we can assist you." />
      </Helmet>
      <HeroSection/>
      <CallToAction/>
      <Features/>
      <Benefits/>
      <Testimonials/>
      <Partners/>
      
    </div>
  )
}

export default Home
