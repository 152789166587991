import React from "react";
import Map from "../components/Map";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <>
      <ContactForm />
      <Map />
    </>
  );
};

export default Contact;
