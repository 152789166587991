import React from 'react';
import { motion, useInView } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faHandshake, faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Assuming you use React Router for navigation

const Features = () => {
  const featuresRef = React.useRef(null);
  const featuresInView = useInView(featuresRef, { once: true });

  return (
    <section ref={featuresRef} className="py-20">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={featuresInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6 }}
        className="container mx-auto px-4"
      >
        <h2 className="text-3xl font-bold text-center mb-8 ">Services</h2>
        <div className="flex flex-wrap justify-center">
          <div className="w-full sm:w-1/2 md:w-1/3 p-4">
            <div className="bg-white rounded-lg shadow-lg p-6 h-full">
              <div className="flex items-center justify-center mb-4">
                <FontAwesomeIcon icon={faChalkboardTeacher} className="text-5xl text-customBlue"/>
              </div>
              <h3 className="text-2xl font-semibold text-customRed mb-2 text-center">Training</h3>
              <p className="text-gray-600 mb-4 text-center">
                Accredited ICT training from leading providers like CompTIA and Cisco. Gain internationally recognized qualifications and career-enhancing skills.
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 p-4">
            <div className="bg-white rounded-lg shadow-lg p-6 h-full">
              <div className="flex items-center justify-center mb-4">
                <FontAwesomeIcon icon={faHandshake} className="text-5xl text-customBlue"/>
              </div>
              <h3 className="text-2xl font-semibold text-customRed mb-2 text-center">Consulting</h3>
              <p className="text-gray-600 mb-4 text-center">
                Custom application development, eCommerce, CRM systems, and robust cybersecurity solutions.
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 p-4">
            <div className="bg-white rounded-lg shadow-lg p-6 h-full">
              <div className="flex items-center justify-center mb-4">
                <FontAwesomeIcon icon={faStoreAlt} className="text-5xl text-customBlue"/>
              </div>
              <h3 className="text-2xl font-semibold text-customRed mb-2 text-center">Reseller</h3>
              <p className="text-gray-600 mb-4 text-center">
                Authorized Microsoft and Dell Reseller offering competitive pricing, expert repairs, and comprehensive IT support services.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <Link to="/services/training" className="bg-buttonBg1 hover:bg-buttonBg2 text-white px-4 py-2 rounded-full border-none cursor-pointer transition duration-300 transform hover:scale-105">
            Explore All Services
          </Link>
        </div>
      </motion.div>
    </section>
  );
};

export default Features;
