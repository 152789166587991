import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const CodingChallenge = () => {
  const [command, setCommand] = useState('');
  const [feedback, setFeedback] = useState('');
  const [feedbackColor, setFeedbackColor] = useState('text-gray-700'); // New state for feedback color
  const [isChallengeComplete, setIsChallengeComplete] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const commandsRef = useRef([]);
  const [targetPosition, setTargetPosition] = useState({ x: 0, y: 0 });
  const [squarePosition, setSquarePosition] = useState({ x: 0, y: 0 });
  const [squareColor, setSquareColor] = useState('blue');
  const [targetColor, setTargetColor] = useState('red'); // New state for target color
  const canvasRef = useRef(null);
  const frequencyRef = useRef({ angle: 0 });

  const gridSize = isMobile ? 25 : 30;
  const squareSize = gridSize;
  const canvasSize = isMobile ? 250 : 300;
  const stepDuration = 2000;

  const getRandomPosition = () => Math.floor(Math.random() * (canvasSize / gridSize)) * gridSize;

  const initializePositions = () => {
    let newTargetX, newTargetY, newSquareX, newSquareY;
    newTargetX = getRandomPosition();
    newTargetY = getRandomPosition();

    do {
      newSquareX = getRandomPosition();
      newSquareY = getRandomPosition();
    } while (newSquareX === newTargetX && newSquareY === newTargetY);

    setTargetPosition({ x: newTargetX, y: newTargetY });
    setSquarePosition({ x: newSquareX, y: newSquareY });
  };

  const animateMovement = (startX, startY, endX, endY, callback) => {
    let startTime = null;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      const currentX = startX + ((endX - startX) * (progress / stepDuration));
      const currentY = startY + ((endY - startY) * (progress / stepDuration));

      const clampedX = Math.min(Math.max(currentX, Math.min(startX, endX)), Math.max(startX, endX));
      const clampedY = Math.min(Math.max(currentY, Math.min(startY, endY)), Math.max(startY, endY));

      if (clampedX < 0 || clampedX > canvasSize - squareSize || clampedY < 0 || clampedY > canvasSize - squareSize) {
        setFeedback('You moved out of bounds! Game over.');
        setFeedbackColor('text-red-700'); // Set feedback color to red
        setIsChallengeComplete(true);
        return;
      }

      setSquarePosition({ x: clampedX, y: clampedY });

      if (progress < stepDuration) {
        requestAnimationFrame(animate);
      } else {
        callback();
      }
    };

    requestAnimationFrame(animate);
  };

  const parseCommands = (cmdList) => {
    let newX = squarePosition.x;
    let newY = squarePosition.y;
    let isAtTarget = false;

    const executeNextCommand = (index) => {
      if (index >= cmdList.length) {
        if (!isAtTarget) {
          setFeedback('Keep going! Try to reach the red target.');
          setFeedbackColor('text-red-700'); // Set feedback color to red
        } else {
          setFeedback('Congratulations! You reached the target!');
          setFeedbackColor('text-green-700'); // Set feedback color to green
        }
        drawCanvas();
        return;
      }

      const cmd = cmdList[index];
      const stepsMatch = cmd.toLowerCase().match(/move (\d+) steps (left|right|up|down)/);

      if (stepsMatch) {
        const steps = parseInt(stepsMatch[1], 10);
        const direction = stepsMatch[2];

        let endX = newX;
        let endY = newY;

        switch (direction) {
          case 'right':
            endX += steps * gridSize;
            break;
          case 'left':
            endX -= steps * gridSize;
            break;
          case 'up':
            endY -= steps * gridSize;
            break;
          case 'down':
            endY += steps * gridSize;
            break;
          default:
            break;
        }

        endX = Math.max(0, Math.min(canvasSize - squareSize, endX));
        endY = Math.max(0, Math.min(canvasSize - squareSize, endY));

        animateMovement(newX, newY, endX, endY, () => {
          newX = endX;
          newY = endY;

          isAtTarget = (
            newX < targetPosition.x + gridSize &&
            newX + squareSize > targetPosition.x &&
            newY < targetPosition.y + gridSize &&
            newY + squareSize > targetPosition.y
          );

          if (isAtTarget) {
            setFeedback('Congratulations! You reached the target!');
            setFeedbackColor('text-green-700'); // Set feedback color to green
            setIsChallengeComplete(true);
            setSquareColor('green');
            setTargetColor('green'); // Change the target color to green
          }

          executeNextCommand(index + 1);
        });
      } else {
        setFeedback('Invalid command format. Please follow: move [number] steps [direction]');
        setFeedbackColor('text-red-700'); // Set feedback color to red
      }
    };

    executeNextCommand(0);
  };

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.strokeStyle = '#d3d3d3';
    ctx.lineWidth = 1;
    for (let i = 0; i < canvas.width; i += gridSize) {
      ctx.beginPath();
      ctx.moveTo(i, 0);
      ctx.lineTo(i, canvas.height);
      ctx.stroke();
    }
    for (let i = 0; i < canvas.height; i += gridSize) {
      ctx.beginPath();
      ctx.moveTo(0, i);
      ctx.lineTo(canvas.width, i);
      ctx.stroke();
    }

    // Draw target
    ctx.fillStyle = targetColor;
    ctx.beginPath();
    ctx.arc(targetPosition.x + gridSize / 2, targetPosition.y + gridSize / 2, 15, 0, Math.PI * 2);
    ctx.fill();

    // Draw frequency waves only if the challenge is not complete
    if (!isChallengeComplete) {
      drawFrequencyWaves(ctx);
    }

    // Draw character (square)
    ctx.fillStyle = squareColor;
    ctx.fillRect(squarePosition.x, squarePosition.y, squareSize, squareSize);
  };

  const drawFrequencyWaves = (ctx) => {
    const centerX = targetPosition.x + gridSize / 2;
    const centerY = targetPosition.y + gridSize / 2;
    const frequencyRadius = 5;
    const frequencyCount = 3;

    frequencyRef.current.angle += 0.05;
    if (frequencyRef.current.angle > Math.PI * 2) frequencyRef.current.angle = 0;

    for (let i = 1; i <= frequencyCount; i++) {
      ctx.beginPath();
      ctx.strokeStyle = `rgba(255, 0, 0, ${1 - i / frequencyCount})`;

      ctx.lineWidth = 2;

      const radius = frequencyRadius * i + 10 * Math.sin(frequencyRef.current.angle * i);
      for (let angle = 0; angle <= Math.PI * 2; angle += 0.1) {
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);

        if (angle === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      }

      ctx.closePath();
      ctx.stroke();
    }
  };

  const executeCommands = () => {
    const cmds = commandsRef.current;
    parseCommands(cmds);
  };

  const handleCommandChange = (e) => {
    setCommand(e.target.value);
  };

  const handleExecuteClick = () => {
    commandsRef.current = command.split(',').map(cmd => cmd.trim());
    executeCommands();
  };

  const handleStartClick = () => {
    initializePositions();
    drawCanvas();
    setIsStarted(true);
    setIsChallengeComplete(false);
    setFeedback('');
    setTargetColor('red')
    setSquareColor('blue')
  };

  useEffect(() => {
    if (isChallengeComplete) {
      setIsStarted(false);
    }
  }, [isChallengeComplete]);

  useEffect(() => {
    if (isStarted) {
      drawCanvas();
      const animate = () => {
        drawCanvas();
        requestAnimationFrame(animate);
      };
      animate();
    }
  }, [isStarted, squarePosition, targetPosition, squareColor, targetColor]);

  return (
    <div className="flex flex-col items-center p-4 md:p-6 mb-2 bg-yellow-50 border border-gray-300 rounded-lg shadow-lg max-w-lg w-full mx-auto">
      <h1 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">Coding Challenge Game</h1>
      <p className="text-base md:text-lg text-gray-700 mb-2">Move the blue square to reach the red target!</p>
      <p className="text-sm md:text-md text-gray-600 mb-4">Use commands to move the blue square. Follow these instructions:</p>
      <ul className="list-disc list-inside mb-4 text-gray-700 text-sm md:text-base">
        <li>Commands format: <code className="bg-gray-200 p-1 rounded">move [number] steps [direction]</code></li>
        <li><code>[number]</code> is a positive integer, <code>[direction]</code> is one of: <code>left</code>, <code>right</code>, <code>up</code>, <code>down</code></li>
        <li>Commands are case-insensitive and extra spaces are ignored.</li>
      </ul>
      <p className="text-sm md:text-md text-gray-700 mb-4 font-bold">Examples:</p>
      <ul className="list-disc list-inside mb-4 text-gray-700 text-sm md:text-base">
        <li><code>move 5 steps right</code> - Moves the square 5 cells to the right.</li>
      </ul>
      <div className="mb-4 w-full max-w-lg">
        {!isStarted ? (
          <button
            className="py-2 px-4 w-full bg-yellow-500 text-white font-semibold rounded-lg shadow-md hover:bg-yellow-600"
            onClick={handleStartClick}
          >
            Start Game
          </button>
        ) : (
          <>
            <textarea
              className="w-full h-24 md:h-18 p-2 mb-4 text-sm md:text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              value={command}
              onChange={handleCommandChange}
              placeholder="Enter your commands here, separated by commas..."
            />
            <button
              className="py-2 px-4 w-full bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600"
              onClick={handleExecuteClick}
              disabled={!command.trim()}
            >
              Execute Commands
            </button>
          </>
        )}
      </div>
      {feedback && (
        <div
          className={`mt-4 p-2 w-full bg-blue-50 border border-blue-400 rounded-lg ${feedbackColor}`}
          dangerouslySetInnerHTML={{ __html: feedback }}
        />
      )}
      <canvas ref={canvasRef} width={canvasSize} height={canvasSize} className="mt-2 border-4 border-yellow-500"></canvas>
    </div>
  );
};

export default CodingChallenge;
