import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaExclamationTriangle } from 'react-icons/fa';

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center">
      <FaExclamationTriangle className="text-8xl text-yellow-500 mb-4" />
      <h1 className="text-6xl font-bold text-gray-800">404</h1>
      <p className="mt-4 text-xl text-gray-600">Oops! The page you’re looking for doesn’t exist.</p>
      <Link to="/" className="mt-6 inline-block px-6 py-3 text-lg font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition flex items-center justify-center">
        <FaHome className="mr-2" />
        Go to Homepage
      </Link>
    </div>
  );
}

export default NotFoundPage;
