import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AiOutlineUser, AiOutlineClockCircle } from 'react-icons/ai';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaShareAlt, FaWhatsapp } from 'react-icons/fa';
import blogPosts from '../data/blogPosts';

const BlogPostDetail = () => {
  const { slug } = useParams();
  
  // Find the blog post that matches the slug
  const post = useMemo(() => blogPosts.find(post => post.slug === slug), [slug]);

  // Memoize the related posts computation
  const relatedPosts = useMemo(() => {
    if (!post) return [];
    return blogPosts.filter(relatedPost => 
      relatedPost.id !== post.id && 
      relatedPost.categories.some(category => post.categories.includes(category))
    );
  }, [post]);

  // Share URL for the post
  const shareUrl = window.location.href;

  if (!post) {
    return <div className="min-h-screen bg-blue-50 py-8 px-4 sm:px-6 lg:px-8">Blog post not found</div>;
  }

  return (
    <div className="min-h-screen bg-blue-50 py-8 px-4 sm:px-6 lg:px-8" style={{ backgroundImage: `url('/images/blog2.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="max-w-3xl mx-auto">
        {/* "All posts" Link at the Top */}

        <div className='flex space-x-4 mb-4 font-roboto'>
  <div className="border-r border-gray-300 border-t-0 border-b-0 border-l-0 pr-4">
    <Link to="/blog" className="text-blue-400 hover:underline">posts</Link>
  </div>
  <div className="border-r border-gray-300 border-t-0 border-b-0 border-l-0 pr-4">
    <Link to="/enrol" className="text-blue-400 hover:underline">enrol</Link>
  </div>
  <div>
    <Link to="/contact" className="text-blue-400 hover:underline">help</Link>
  </div>
</div>




       

        <article className="bg-white rounded-lg shadow-md overflow-hidden mb-8 transition duration-300 ease-in-out transform">
          {/* Featured Image */}
          <img src={post.featuredImage} alt={post.title} className="w-full h-64 object-cover rounded-t-lg" />

          <div className="p-6">
            <h1 className="text-3xl font-semibold text-gray-900 mb-4">{post.title}</h1>
            <div className="flex items-center text-gray-600 mb-4">
              <AiOutlineClockCircle className="mr-2" style={{ fontSize: '1.2em' }} />
              <span>{post.date}</span>
              <AiOutlineUser className="ml-4 mr-2" style={{ fontSize: '1.2em' }} />
              <span>{post.author}</span>
            </div>
            <p className="text-gray-800 mb-6">{post.content}</p>

            {/* Social Media Share Buttons */}
            <div className="flex space-x-4 mt-6">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800"
              >
                <FaFacebookF className="text-2xl" />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(post.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-600"
              >
                <FaTwitter className="text-2xl" />
              </a>
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(post.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700 hover:text-blue-900"
              >
                <FaLinkedinIn className="text-2xl" />
              </a>
              <a
                href={`https://wa.me/?text=${encodeURIComponent(post.title)}%20${encodeURIComponent(shareUrl)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-500 hover:text-green-700"
              >
                <FaWhatsapp className="text-2xl" />
              </a>
              <a
                href={`https://www.reddit.com/submit?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(post.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-orange-500 hover:text-orange-700"
              >
                <FaShareAlt className="text-2xl" />
              </a>
            </div>

            {/* Comments Section */}
            {post.comments && (
              <div className="mt-6 border-t border-gray-200 pt-4">
                <h2 className="text-lg font-semibold mb-2">Comments</h2>
                <p className="text-gray-800">{post.comments}</p>
              </div>
            )}

            {/* Related Posts */}
            {relatedPosts.length > 0 && (
              <div className="mt-6 border-t border-gray-200 pt-4">
                <h2 className="text-lg font-semibold mb-2">Related Posts</h2>
                <div className="space-y-2">
                  {relatedPosts.map(relatedPost => (
                    <div key={relatedPost.id}>
                      <Link to={`/blog/${relatedPost.slug}`} className="text-blue-600 hover:underline">
                        {relatedPost.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogPostDetail;
