export const courses = [
  { 
    originalName: 'agriseta.html', 
    displayName: 'AGRISETA', 
    imageUrl: '/images/agl.png',
    categories: ['Agriculture', 'AGRISETA']
  },
  { 
    originalName: 'alcatel.html', 
    displayName: 'ALCATEL', 
    imageUrl: '/images/al.png',
    categories: ['Telecommunications', 'ALCATEL']
  },
 
  { 
    originalName: 'comptiaa+.html', 
    displayName: 'COMPTIA A+', 
    imageUrl: '/images/logoaplus.svg',
    categories: ['IT Certification', 'CompTIA']
  },
  { 
    originalName: 'comptiacourses.html', 
    displayName: 'COMPTIA CORE', 
    imageUrl: '/images/comptiacore.png',
    categories: ['IT Certification', 'CompTIA']
  },
  { 
    originalName: 'cmptian+.html', 
    displayName: 'COMPTIA N+', 
    imageUrl: '/images/logonetworkplus.svg',
    categories: ['CompTIA']
  },
  { 
    originalName: 'comptiacybersec.html', 
    displayName: 'COMPTIA CYBERSECURITY', 
    imageUrl: '/images/logocysaplus-01.svg',
    categories: ['CompTIA']
  },
  { 
    originalName: 'comptiafundamentals.html', 
    displayName: 'COMPTIA FUNDAMENTALS', 
    imageUrl: '/images/logoitfundamentals.svg',
    categories: [ 'CompTIA']
  },
  { 
    originalName: 'comptiainfrastructure.html', 
    displayName: 'COMPTIA INFRASTRUCTURE', 
    imageUrl: '/images/comptiacore.png',
    categories: [ 'CompTIA']
  },
 

  { 
    originalName: 'ibmdatapower.html', 
    displayName: 'IBM DATAPOWER', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmdb2c.html', 
    displayName: 'IBM DB2C', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmendp.html', 
    displayName: 'IBM ENDPOINT', 
    imageUrl: '/images/ibm.png',
    categories: [ 'IBM']
  },
  { 
    originalName: 'ibmguardium.html', 
    displayName: 'IBM GUARDIUM', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmi.html', 
    displayName: 'IBM I', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmitcam.html', 
    displayName: 'IBM ITCAM', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmblockchain.html', 
    displayName: 'IBM BLOCKCHAIN', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmpowersystem.html', 
    displayName: 'IBM POWER SYSTEM', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmsystemx.html', 
    displayName: 'IBM SYSTEM X', 
    imageUrl: '/images/ibm.png',
    categories: [ 'IBM']
  },
  { 
    originalName: 'ibmsystemstore.html', 
    displayName: 'IBM SYSTEM STORE', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmtivolistore.html', 
    displayName: 'IBM TIVOLI STORE', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmwebsphereappserve.html', 
    displayName: 'IBM WEBSPHERE APP SERVER', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmwebspheremb.html', 
    displayName: 'IBM WEBSPHERE MB', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'ibmwebspheremq.html', 
    displayName: 'IBM WEBSPHERE MQ', 
    imageUrl: '/images/ibm.png',
    categories: ['IBM']
  },
  { 
    originalName: 'itil.html', 
    displayName: 'ITIL COURSES', 
    imageUrl: '/images/itil.png',
    categories: ['ITIL']
  },
  { 
    originalName: 'pecb.html', 
    displayName: 'PECB COURSES', 
    imageUrl: '/images/pecb2.png',
    categories: [ 'PECB']
  },
  { 
    originalName: 'pecbis.html', 
    displayName: 'PECB IS', 
    imageUrl: '/images/pecb2.png',
    categories: [ 'PECB']
  },
  { 
    originalName: 'pecbcontinuity.html', 
    displayName: 'PECB CONTINUITY', 
    imageUrl: '/images/pecb2.png',
    categories: [ 'PECB']
  },
  { 
    originalName: 'pecbcybersec.html', 
    displayName: 'PECB CYBERSECURITY', 
    imageUrl: '/images/pecb2.png',
    categories: [ 'PECB']
  },
  { 
    originalName: 'pecbgovrisk.html', 
    displayName: 'PECB GOVERNANCE RISK', 
    imageUrl: '/images/pecb2.png',
    categories: [ 'PECB']
  },
  { 
    originalName: 'pecbprivacy&data.html', 
    displayName: 'PECB PRIVACY & DATA', 
    imageUrl: '/images/pecb2.png',
    categories: [ 'PECB']
  },
  { 
    originalName: 'pecbquality&manage.html', 
    displayName: 'PECB QUALITY & MANAGEMENT', 
    imageUrl: '/images/pecb2.png',
    categories: [ 'PECB']
  },
  { 
    originalName: 'pecbsustanability.html', 
    displayName: 'PECB SUSTAINABILITY', 
    imageUrl: '/images/pecb2.png',
    categories: [ 'PECB']
  },
  { 
    originalName: 'popia.html', 
    displayName: 'POPIA COURSES', 
    imageUrl: '/images/popia.png',
    categories: ['Legal Compliance']
  },
  { 
    originalName: 'programdev.html', 
    displayName: 'PROGRAM DEVELOPMENT COURSES', 
    imageUrl: '/images/pecb2.png',
    categories: ['Programming']
  },
  { 
    originalName: 'certnexus.html', 
    displayName: 'CERTNEXUS COURSES', 
    imageUrl: '/images/certnexus2.png',
    categories: ['Certnexus']
  },
  { 
    originalName: 'certnexusai.html', 
    displayName: 'CERTNEXUS AI', 
    imageUrl: '/images/certnexus2.png',
    categories: ['Certnexus']
  },
  { 
    originalName: 'certnexuscyber2.html', 
    displayName: 'CERTNEXUS CYBERSECURITY 2', 
    imageUrl: '/images/certnexus2.png',
    categories: ['Certnexus']
  },
  { 
    originalName: 'certnexuscybersec.html', 
    displayName: 'CERTNEXUS CYBERSECURITY', 
    imageUrl: '/images/certnexus2.png',
    categories: ['Certnexus']
  },
  { 
    originalName: 'certnexusdatascience.html', 
    displayName: 'CERTNEXUS DATA SCIENCE', 
    imageUrl: '/images/certnexus2.png',
    categories: ['Certnexus']
  },
  { 
    originalName: 'certnexusiot.html', 
    displayName: 'CERTNEXUS IOT', 
    imageUrl: '/images/certnexus2.png',
    categories: ['Certnexus']
  },
  { 
    originalName: 'microsoft365.html', 
    displayName: 'MICROSOFT 365', 
    imageUrl: '/images/Microsoft-365.webp',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftadmin.html', 
    displayName: 'MICROSOFT ADMIN', 
    imageUrl: '/images/admin.webp',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftaiengineer.html', 
    displayName: 'MICROSOFT AI ENGINEER', 
    imageUrl: '/images/microsoft.png',
    categories: ['Microsoft']
  },
  { 
    originalName: 'microsoftazurefundamentals.html', 
    displayName: 'MICROSOFT AZURE FUNDAMENTALS', 
    imageUrl: '/images/azure.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftcourses.html', 
    displayName: 'MICROSOFT COURSES', 
    imageUrl: '/images/microsoft.png',
    categories: ['Microsoft']
  },
  { 
    originalName: 'microsoftdataandai.html', 
    displayName: 'MICROSOFT DATA & AI', 
    imageUrl: '/images/microsoft.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftdataanaass.html', 
    displayName: 'MICROSOFT DATA ANALYTICS & ASSOCIATE', 
    imageUrl: '/images/microsoft.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftdatabaseadm.html', 
    displayName: 'MICROSOFT DATABASE ADMINISTRATION', 
    imageUrl: '/images/microsoft.png',
    categories: ['Microsoft']
  },
  { 
    originalName: 'microsoftdataenginee.html', 
    displayName: 'MICROSOFT DATA ENGINEER', 
    imageUrl: '/images/microsoft.png',
    categories: ['Microsoft']
  },
  { 
    originalName: 'microsoftdesktop.html', 
    displayName: 'MICROSOFT DESKTOP', 
    imageUrl: '/images/microsoft.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftdevop.html', 
    displayName: 'MICROSOFT DEVOPS', 
    imageUrl: '/images/microsoft.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftiotdev.html', 
    displayName: 'MICROSOFT IOT DEVELOPMENT', 
    imageUrl: '/images/microsoft.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftmigrationwo.html', 
    displayName: 'MICROSOFT MIGRATION WORKSHOP', 
    imageUrl: '/images/microsoft.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftsapwork.html', 
    displayName: 'MICROSOFT SAP WORKLOADS', 
    imageUrl: '/images/microsoft.png',
    categories: ['Microsoft']
  },
  { 
    originalName: 'microsoftsecengineer.html', 
    displayName: 'MICROSOFT SECURITY ENGINEER', 
    imageUrl: '/images/microsoft.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftsharep.html', 
    displayName: 'MICROSOFT SHAREPOINT', 
    imageUrl: '/images/microsoft.png',
    categories: ['Microsoft']
  },
  { 
    originalName: 'microsoftsolart.html', 
    displayName: 'MICROSOFT SOLAR TRAINING', 
    imageUrl: '/images/microsoft.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftsqlsever.html', 
    displayName: 'MICROSOFT SQL SERVER', 
    imageUrl: '/images/microsoft.png',
    categories: ['Microsoft']
  },
  { 
    originalName: 'microsoftvs.html', 
    displayName: 'MICROSOFT VISUAL STUDIO', 
    imageUrl: '/images/microsoft.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftwindows2016.html', 
    displayName: 'MICROSOFT WINDOWS 2016', 
    imageUrl: '/images/microsoft.png',
    categories: [ 'Microsoft']
  },
  { 
    originalName: 'microsoftdevasso.html', 
    displayName: 'MICROSOFT DEVELOPMENT ASSOCIATE', 
    imageUrl: '/images/microsoft.png',
    categories: ['Microsoft']
  },
  { 
    originalName: 'mictsetacourses.html', 
    displayName: 'MICT SETA COURSES', 
    imageUrl: '/images/mict2.png',
    categories: ['MICT SETA']
  },
  { 
    originalName: 'softskillsbusinesscourses.html', 
    displayName: 'SOFT SKILLS BUSINESS COURSES', 
    imageUrl: '/images/soft.png',
    categories: ['Soft Skills']
  },
  { 
    originalName: 'softskillsinterpcourses.html', 
    displayName: 'SOFT SKILLS INTERPERSONAL COURSES', 
    imageUrl: '/images/soft.png',
    categories: ['Soft Skills']
  },
  { 
    originalName: 'softskillsintrapcourses.html', 
    displayName: 'SOFT SKILLS INTRAPERSONAL COURSES', 
    imageUrl: '/images/soft.png',
    categories: ['Soft Skills']
  },
  { 
    originalName: 'softskillssalesourses.html', 
    displayName: 'SOFT SKILLS SALES COURSES', 
    imageUrl: '/images/soft.png',
    categories: ['Soft Skills']
  },
  { 
    originalName: 'softskillscustomercourses.html', 
    displayName: 'SOFT SKILLS CUSTOMER COURSES', 
    imageUrl: '/images/soft.png',
    categories: ['Soft Skills']
  },
  { 
    originalName: 'softskillssupercourses.html',
    displayName: 'SOFT SKILLS SUPERVISORY COURSES', 
    imageUrl: '/images/soft.png',
    categories: ['Soft Skills']
  },
  { 
    originalName: 'softskillsbusiness.html', 
    displayName: 'SOFT SKILLS BUSINESS', 
    imageUrl: '/images/soft.png',
    categories: ['Soft Skills']
  },
  { 
    originalName: 'softskillstfcourse.html', 
    displayName: 'SOFT SKILLS TF COURSES', 
    imageUrl: '/images/soft.png',
    categories: ['Soft Skills']
  },
  { 
    originalName: 'ciscoccna.html', 
    displayName: 'CISCO CCNA', 
    imageUrl: '/images/cisco3.png',
    categories: ['Cisco', 'Networking']
  },
  { 
    originalName: 'ciscoccnp.html', 
    displayName: 'CISCO CCNP', 
    imageUrl: '/images/cisco3.png',
    categories: ['Cisco', 'Networking']
  },
  { 
    originalName: 'ciscocybersec.html', 
    displayName: 'CISCO CYBERSECURITY', 
    imageUrl: '/images/cisco3.png',
    categories: ['Cisco', 'Cybersecurity']
  },
  { 
    originalName: 'ciscodatacience.html', 
    displayName: 'CISCO DATA SCIENCE', 
    imageUrl: '/images/cisco3.png',
    categories: ['Cisco', 'Data Science']
  },
  { 
    originalName: 'ciscofirepower.html', 
    displayName: 'CISCO FIREPOWER', 
    imageUrl: '/images/cisco3.png',
    categories: ['Cisco', 'Security']
  },
  { 
    originalName: 'ciscooperatingsys.html', 
    displayName: 'CISCO OPERATING SYSTEMS', 
    imageUrl: '/images/cisco3.png',
    categories: ['Cisco', 'Operating Systems']
  },
  { 
    originalName: 'ciscoprogramming.html', 
    displayName: 'CISCO PROGRAMMING', 
    imageUrl: '/images/cisco3.png',
    categories: ['Cisco', 'Programming']
  },
];
