import React from "react";

const Map = React.memo(() => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14345.44513721506!2d28.12454!3d-25.98898!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e956e178381e3b9%3A0x1cb0aca2fa587590!2s136%202nd%20St%2C%20Randjespark%2C%20Midrand%2C%201685!5e0!3m2!1sen!2sza!4v1721141374912!5m2!1sen!2sza"
      width="100%"
      height="450"
      className="mx-auto border border-gray-400 shadow-md mb-8"
      title="Google Maps Location"
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
});

export default Map;
