import React, { useMemo } from 'react';
import { FaRobot, FaBrain, FaLightbulb, FaQuestionCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Component for Service Card
const ServiceCard = ({ icon, title, description, image }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="relative h-48">
        <img src={image} alt={title} className="object-cover w-full h-full" />
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white">
          <div className="text-center px-4">
            <div className="text-3xl mb-2">{icon}</div>
            <h2 className="text-xl font-semibold mb-2">{title}</h2>
            <p className="text-md">{description}</p>
          </div>
        </div>
      </div>
      <div className="p-4 flex justify-center">
        <Link
          to="/contact"
          className="block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md flex items-center justify-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          <span className="flex justify-center items-center">
            <FaQuestionCircle className="mr-2" /> Enquire
          </span>
        </Link>
      </div>
    </div>
  );
};

// Main Component
const Ai = () => {
  // Memoize the SERVICE_CARDS array to prevent re-calculations on each render
  const SERVICE_CARDS = useMemo(() => [
    {
      icon: <FaRobot />,
      title: "Robotic Process Automation",
      description: "Automate repetitive tasks and workflows with AI-powered bots.",
      image: "/images/bots.jpeg",
    },
    {
      icon: <FaBrain />,
      title: "Machine Learning Solutions",
      description: "Develop and deploy machine learning models for predictive analytics.",
      image: "/images/ml.jpeg",
    },
    {
      icon: <FaLightbulb />,
      title: "AI Consulting Services",
      description: "Get expert advice and solutions for implementing AI in your business.",
      image: "/images/aicon.jpeg",
    },
  ], []); // Empty dependency array means this will only be computed once

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <h1 className="text-2xl text-customRed font-bold mb-8 text-center">Artificial Intelligence Solutions</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {SERVICE_CARDS.map((card, index) => (
          <ServiceCard
            key={index}
            icon={card.icon}
            title={card.title}
            description={card.description}
            image={card.image}
          />
        ))}
      </div>
    </div>
  );
}

export default Ai;
