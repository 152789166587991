import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaBuilding, FaCoins, FaHeartbeat, FaQuestionCircle, FaUniversity } from 'react-icons/fa';

// Component for Service Card
const ServiceCard = ({ icon, title, description, image }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="relative h-48">
        <img src={image} alt={title} className="object-cover w-full h-full" />
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white">
          <div className="text-center px-4">
            <div className="text-3xl mb-2">{icon}</div>
            <h2 className="text-xl font-semibold mb-2">{title}</h2>
            <p className="text-md">{description}</p>
          </div>
        </div>
      </div>
      <div className="p-4 flex justify-center">
        <Link
          to="/contact"
          className="block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md flex items-center justify-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          <span className="flex justify-center items-center">
            <FaQuestionCircle className="mr-2" /> Enquire
          </span>
        </Link>
      </div>
    </div>
  );
};

// Main Component
const Cybersecurity = () => {
  // Memoize the SERVICE_CARDS array to prevent re-calculations on each render
  const SERVICE_CARDS = useMemo(() => [
    {
      icon: <FaCoins />,
      title: "Financial Services Security",
      description: "We secure banking, wealth, and finance environments with robust security.",
      image: "/images/financial.jpeg",
    },
    {
      icon: <FaUniversity />,
      title: "Higher Education Security",
      description: "We protect campuses and cloud applications for educational institutions.",
      image: "/images/edu.jpeg",
    },
    {
      icon: <FaHeartbeat />,
      title: "Healthcare Security",
      description: "We centralize security for hospitals and clinics.",
      image: "/images/health.jpeg",
    },
    {
      icon: <FaBuilding />,
      title: "Enterprise and Small Business Cybersecurity",
      description: "We enhance security for distributed organizations of any size.",
      image: "/images/enterp.jpeg",
    },
  ], []); // Empty dependency array means this will only be computed once

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <h1 className="text-2xl text-customRed font-bold mb-8 text-center">Cyber Security Solutions</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {SERVICE_CARDS.map((card, index) => (
          <ServiceCard
            key={index}
            icon={card.icon}
            title={card.title}
            description={card.description}
            image={card.image}
          />
        ))}
      </div>
    </div>
  );
}

export default Cybersecurity;
