import React, { useMemo } from 'react';
import { FaTools, FaQuestionCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Component for Service Card
const ServiceCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="relative h-48">
        {/* Placeholder image */}
        <div className="bg-gray-400 w-full h-full"></div>
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white">
          <div className="text-center px-4">
            <div className="text-3xl mb-2">{icon}</div>
            <h2 className="text-xl font-semibold mb-2">{title}</h2>
            <p className="text-md">{description}</p>
          </div>
        </div>
      </div>
      <div className="p-4 flex justify-center">
        <Link
          to="/contact"
          className="block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md flex items-center justify-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          <span className="flex justify-center items-center">
            <FaQuestionCircle className="mr-2" /> Enquire
          </span>
        </Link>
      </div>
    </div>
  );
};

// Main Component
const Crm = () => {
  // Memoize the SERVICE_CARDS array to prevent re-calculations on each render
  const SERVICE_CARDS = useMemo(() => [
    {
      icon: <FaTools />,
      title: "Contact Management",
      description: "Organize and manage your contacts effectively for better customer relationships.",
    },
    {
      icon: <FaTools />,
      title: "Marketing Automation",
      description: "Automate marketing processes to nurture leads and drive conversions.",
    },
    {
      icon: <FaTools />,
      title: "Sales Forecasting",
      description: "Predict sales trends and outcomes to optimize your sales strategies.",
    },
    {
      icon: <FaTools />,
      title: "Lead Management",
      description: "Capture, qualify, and track leads throughout the sales funnel.",
    },
    {
      icon: <FaTools />,
      title: "Email Marketing",
      description: "Create and manage email campaigns to engage with your audience.",
    },
    {
      icon: <FaTools />,
      title: "Task Management",
      description: "Organize tasks and streamline workflows for improved productivity.",
    },
    {
      icon: <FaTools />,
      title: "Opportunity Management",
      description: "Track and manage sales opportunities to maximize revenue potential.",
    },
  ], []); // Empty dependency array means this will only be computed once

  return (
    <div className='min-h-screen bg-gray-50 p-8'>
      <h1 className="text-2xl text-customRed font-bold mb-8 text-center">CRM Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {SERVICE_CARDS.map((card, index) => (
          <ServiceCard
            key={index}
            icon={card.icon}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>
    </div>
  );
}

export default Crm;
