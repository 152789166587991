import React from 'react';
import { FaCogs, FaBriefcase, FaLightbulb, FaQuestionCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Transformation = () => {
  return (
    <div className="min-h-fit bg-gray-50 p-8">
      <h1 className="text-2xl text-customRed font-bold mb-8 text-center">Digital Transformation Solutions</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <ServiceCard
          icon={<FaCogs />}
          title="Process Automation"
          description="Automate business processes to improve efficiency and reduce costs."
          image="/images/auto.jpeg"
        />
        <ServiceCard
          icon={<FaBriefcase />}
          title="Enterprise Mobility"
          description="Enable remote work and enhance productivity with mobile solutions."
          image="/images/mobility.jpeg"
        />
        <ServiceCard
          icon={<FaLightbulb />}
          title="Innovation Strategy"
          description="Develop strategies to foster innovation and stay competitive."
          image="/images/inno.jpeg"
        />
      </div>
    </div>
  );
}

const ServiceCard = ({ icon, title, description, image }) => {
  const handleEnquiryClick = () => {
    // Replace with your actual enquiry handling logic
    window.location.href = '/contact';
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="relative h-48">
        <img src={image} alt={title} className="object-cover w-full h-full" />
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white">
          <div className="text-center px-4">
            <div className="text-3xl mb-2">{icon}</div>
            <h2 className="text-xl font-semibold mb-2">{title}</h2>
            <p className="text-md">{description}</p>
          </div>
        </div>
      </div>
      <div className="p-4 flex justify-center">
      <Link
        to="/contact"
        className="block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md flex items-center justify-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400"
      >
        <span className="flex justify-center items-center">
          <FaQuestionCircle className="mr-2" /> Enquire
        </span>
      </Link>
    </div>
    </div>
  );
}

export default Transformation;
