import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import { FaPaperPlane } from "react-icons/fa";

export const QuoteModal = ({ product, onClose }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [specifications, setSpecifications] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // Add this state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set loading state to true

    const requestData = {
      fullName,
      email,
      phoneNumber,
      companyName,
      companyAddress,
      productTitle: product.title,
      productImage: product.imgSrc,
      quantity,
      specifications: Object.entries(specifications).map(
        ([fieldName, value]) => ({
          fieldName,
          value,
        })
      ),
    };

    try {
      const response = await fetch(
        "https://ikusasasolutions.co.za/quotation.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit quotation request");
      }

      onClose();

      Swal.fire({
        title: "Thank You!",
        text: "Your quotation has been submitted successfully. Our team will contact you shortly.",
        icon: "success",
        confirmButtonText: "Ok",
      });

      const responseData = await response.json();
      console.log(responseData);

      onClose();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  const handleSpecificationChange = (e) => {
    const { name, value } = e.target;
    setSpecifications((prevSpecs) => ({
      ...prevSpecs,
      [name]: value,
    }));
  };

  const renderProductSpecifications = () => {
    if (product.specifications && product.specifications.length > 0) {
      return product.specifications.map((spec, index) => (
        <div key={index} className="mb-4">
          <label
            htmlFor={spec.fieldName.toLowerCase().replace(/\s/g, "")}
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            {spec.fieldName}
          </label>
          {spec.options ? (
            <select
              id={spec.fieldName.toLowerCase().replace(/\s/g, "")}
              name={spec.fieldName.toLowerCase().replace(/\s/g, "")}
              onChange={handleSpecificationChange}
              className="w-full px-3 py-2 placeholder-gray-400 border rounded-lg focus:outline-none focus:border-blue-500"
              required
            >
              {spec.options.map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              id={spec.fieldName.toLowerCase().replace(/\s/g, "")}
              name={spec.fieldName.toLowerCase().replace(/\s/g, "")}
              onChange={handleSpecificationChange}
              className="w-full px-3 py-2 placeholder-gray-400 border rounded-lg focus:outline-none focus:border-blue-500"
              placeholder={`Enter ${spec.fieldName}`}
              required={spec.required}
            />
          )}
        </div>
      ));
    } else {
      return null;
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded shadow-xl max-w-md w-full">
          <div className="p-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold text-customBlue">
                {product.title} Quotation Request
              </h2>
              <button
                className="text-gray-400 hover:text-gray-600 focus:outline-none"
                onClick={onClose}
              >
                <FiX className="h-6 w-6" />
              </button>
            </div>
            <div className="flex justify-center mb-4">
              <img
                src={product.imgSrc}
                alt={product.title}
                className="w-[20%] object-cover"
              />
            </div>

            <div className="mb-8">
              <h3 className="text-lg font-semibold mb-2 text-customRed">
                Customer Information
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-4">
                  <div className="mb-4">
                    <label
                      htmlFor="fullName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="w-full px-3 py-2 placeholder-gray-400 border rounded-lg focus:outline-none focus:border-blue-500"
                      placeholder="Enter your full name"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full px-3 py-2 placeholder-gray-400 border rounded-lg focus:outline-none focus:border-blue-500"
                      placeholder="Enter your email address"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="phoneNumber"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="w-full px-3 py-2 placeholder-gray-400 border rounded-lg focus:outline-none focus:border-blue-500"
                      placeholder="Enter your phone number"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="w-full px-3 py-2 placeholder-gray-400 border rounded-lg focus:outline-none focus:border-blue-500"
                      placeholder="Enter your company name"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="companyAddress"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Company Address
                    </label>
                    <textarea
                      id="companyAddress"
                      name="companyAddress"
                      value={companyAddress}
                      onChange={(e) => setCompanyAddress(e.target.value)}
                      rows="2"
                      className="w-full px-3 py-2 placeholder-gray-400 border rounded-lg focus:outline-none focus:border-blue-500"
                      placeholder="Enter your company address"
                    ></textarea>
                  </div>
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-2 text-customRed">
                    Product Information
                  </h3>
                  <div className="grid grid-cols-1 gap-4">
                    {renderProductSpecifications()}
                    <div className="mb-4">
                      <label
                        htmlFor="quantity"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Quantity
                      </label>
                      <input
                        type="number"
                        id="quantity"
                        name="quantity"
                        value={quantity}
                        onChange={(e) => setQuantity(parseInt(e.target.value))}
                        className="w-full px-3 py-2 placeholder-gray-400 border rounded-lg focus:outline-none focus:border-blue-500"
                        placeholder="Enter quantity"
                        min="1"
                        required
                      />
                    </div>
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <div className="flex items-center opacity-50 cursor-not-allowed">
                            <ClipLoader size={20} color="#ffffff" />{" "}
                            <span>Submit</span>
                          </div>
                        ) : (
                          <div className="flex justify-center">
                            {" "}
                            <FaPaperPlane
                              size={20}
                              color="#ffffff"
                              className="mr-1"
                            />{" "}
                            <span>Submit</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
